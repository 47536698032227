.settings-row {
    display: flex;
    flex-direction: row;
    padding: 16px;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    order: 1;
}

.settings-card-container {
    padding: 16px;
    order: 3;
}