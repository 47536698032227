.scramble-title {
    color: white;
    text-align: center;
}

.scramble-display-container {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 8px;
}