.timer-container {
    background: #CBCECF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 2;
    flex-basis: 50vw;
    width: 100%;
    order: 1;
}

.timer-card {
    margin: 24px;
    border-radius: 16px !important;
    background: #051014 !important;
    order: 2;
}

.timer-content {
    width: 100%;
    height: 41vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.current-time {
    font-family: digital-clock-font, serif;
    font-size: 20vw;
    color: white;
}