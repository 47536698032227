.manage-sessions-card {
  .title-row {
    justify-content: space-between;
  }

  .session-details-form {
    justify-content: flex-start;
    padding: 16px 0;
  }

  .action-section {
    justify-content: flex-end;
  }
}