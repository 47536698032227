.session-statistics-container {
    width: 100%;
    flex-basis: 17vw;
    flex-grow: 1;
    background: #BCF8EC;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    order: 3;
    min-width: 220px;
}

.stats-container {
    overflow-y: auto;
    width: 100%;
}

.session-stats-title {
    text-transform: uppercase;
    text-align: center;
}

.stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid black;
    max-height: 69vh;
    overflow-y: scroll;
}

.stat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.stat-label {
    padding-right: 8px;
    font-weight: bold;
}

.stat-value {
    /*font-family: digital-clock-font, serif;*/
}
