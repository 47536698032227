.timer-settings-menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #051014 !important;
    color: white !important;
}

.settings-title {
    text-align: center;
}

.timer-settings-container {
    text-align: right;
    padding: 8px;
}