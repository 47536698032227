.solve-history-and-timer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
}

.scramble-display-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #051014;
  padding: 16px;
}

.logo {
  cursor: pointer;
  padding: 16px;
  width: 20vw;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .logo-title {
    letter-spacing: 3px;
    color: white;
    font-size: 2vw;
  }
}

.user-name {
  padding-right: 8px;
}

.log-out {
  cursor: pointer;
}

.log-out-and-user-name {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  width: 20vw;
  color: white;
  font-weight: bold;
}

.title {
  font-weight: bold;
  text-align: center;
}

@media(max-width: 768px) {
  .timer-container  {
    order: 0;
  }
  .timer-card  {
    order: 1;
  }
  .timer-info-chip-container {
    order: 2;
  }
}