.session-management-container {
  padding: 16px;

  .card-container {
    padding: 16px 0;
  }
  .session-management-card {
    width: 100%;
    padding: 16px;
  }

  .session-management-card-content {
    width: 100%;
  }

  .session-swap-container {
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 16px;
  }

  .source-session-solves-container {
    //width: 45%;
  }

  .swap-action-container {
    width: 10%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .target-session-solves-container {
    //width: 45%;
  }

  .right-arrow {
    cursor: pointer;
  }
}