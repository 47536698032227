@font-face{
  font-family:'digital-clock-font';
  src: url('../../fonts/digital-7/digital-7.ttf');
}

@font-face {
  font-family: 'roboto';
  src: url('../../fonts/Roboto/Roboto-Black.ttf')
}

* {
  font-family: roboto, sans-serif;
}

.page {
  background: #CBCECF;
  min-width: 100vw;
  min-height: 100vh;
}

.label {
  font-style: italic;
  font-weight: bold;
  padding-right: 16px;
}
