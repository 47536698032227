.solve-history-container {
    background: #BCF8EC;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    width: 100%;
    flex-grow: 1;
    flex-basis: 17vw;
    overflow-y: auto;
    order: 1;
    min-width: 220px;
}

.solve-container {
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    color: black;
    padding: 16px;
}

.solve-container:last-child {
    border-bottom: 0;
}

.solve-history-title {
    text-transform: uppercase;
}

.solves {
    max-height: 68vh;
    overflow-y: auto;
}

.label-and-time {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
}

.label {
    font-weight: bold;
    padding-right: 8px;
}

.delete-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.delete-button {
    cursor: pointer;
    color: #051014 !important;
}

.plus-2-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.scroll-to-top-chip {
    position: absolute;
    margin: 8px;
    opacity: 80%;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}