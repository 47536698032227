#root {
  @import 'static/scss/page/Home';
  @import 'static/scss/page/SessionManagement';
  @import 'static/scss/site/Flex';
  @import 'static/scss/component/ManageSessionsCard';
}

@import 'static/scss/site/Default';

body {
  margin: 0;
}
.amplify-input {
  color: white !important;
}

.amplify-tabs-item:focus {
  color: #bcf8ec;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.amplify-tabs-item:hover {
  color: #bcf8ec;
}

.authentication-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.login-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blink {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}
